<template>
    <div>
        <content-with-sidebar class="cws-container cws-sidebar-right blog-wrapper">
            <div class="blog-detail-wrapper">
                <b-row>
                    <b-col cols="12">
                        <b-card :img-src="featuredImage" img-top img-alt="Blog Detail Pic" :title="title">
                            <b-media no-body>
                                <b-media-aside vertical-align="center" class="mr-50">
                                    <b-avatar href="javascript:void(0)" size="24" :src="thumbnailImage" />
                                </b-media-aside>
                                <b-media-body>
                                    <small class="text-muted mr-50">by</small>
                                    <small>
                                        <b-link class="text-body"> {{ author }} </b-link>
                                    </small>
                                    <span class="text-muted ml-75 mr-50">|</span>
                                    <small class="text-muted">
                                        {{ formatDate(createdAt) }}
                                    </small>
                                </b-media-body>
                            </b-media> 

                            <div class="bg-light p-1 my-2">
                                <strong>
                                    SPL:
                                </strong>
                                <br>
                                <p class="mb-0">
                                    {{ spl }}
                                </p>
                            </div>

                            <div class="blog-content mb-4" v-html="description" />

                            <div class="bg-light-toggle rounded p-2 mt-2 p-3" v-if="isPropertiesSet">
                                <div class="row">
                                    <div class="col-lg-3 mb-1" v-for="(property, index) in properties" :key="index">
                                        <span v-if="index == 'mitre_attack'">
                                            <small class="d-block">
                                                <strong> Mitre Att&ck </strong>
                                            </small>
                                            <small v-for="(prop, i) in property" :key="i">
                                                <b-badge
                                                    @click="techniqueHandler(prop)"
                                                    variant="primary"
                                                    class="mr-1 mitre_badge"
                                                >
                                                    {{ prop.name }}
                                                </b-badge>
                                            </small>
                                        </span>
                                        <span v-else>
                                            <small class="d-block">
                                                <strong> {{ toStartCase(index, "_", " ") }} : </strong>
                                            </small>
                                            <small v-for="(prop, i) in property" :key="i">
                                                <b-badge class="mr-1" variant="light-secondary">
                                                    {{ prop.name }}
                                                </b-badge>
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <hr class="mt-3" />

                            <div class="attachments_area mt-3" v-if="attachments">
                                <h4 class="mb-1 font-weight-bold">Attachments</h4>
                                <div
                                    class="attachment_box bg-light-toggle p-1 rounded d-flex flex-row align-items-end flex-wrap"
                                >
                                    <b-card
                                        v-for="(attachment, index) in attachments"
                                        :key="index"
                                        class="attachment_item mb-0 mr-1 shadow-none"
                                        bg-variant="light-primary"
                                        border-variant="primary"
                                        text-variant="white"
                                    >
                                        <span class="name mr-1">
                                            <small>{{ attachment.media.filename }}</small>
                                        </span>
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="primary"
                                            class="btn-icon icon"
                                            :href="attachment.preview"
                                            target="_blank"
                                            download
                                        >
                                            <feather-icon icon="DownloadIcon" />
                                        </b-button>
                                    </b-card>
                                </div>
                                <hr class="mt-3" />
                            </div>

                            <div class="d-flex mt-3 align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <!-- <div class="d-flex align-items-center mr-1">
                                        <b-link class="mr-50">
                                            <feather-icon icon="MessageSquareIcon" size="21" class="text-body" />
                                        </b-link>
                                        <b-link>
                                            <div class="text-body">19.1k</div>
                                        </b-link>
                                    </div> -->
                                    <div class="d-flex align-items-center">
                                        <b-button
                                            class="mr-50 btn-icon"
                                            size="sm"
                                            @click="favoriteToggleHandler(isFavorite ? 'remove' : 'add')"
                                            :variant="isFavorite ? 'primary' : 'secondary'"
                                        >
                                            <feather-icon size="17" icon="HeartIcon" />
                                        </b-button>
                                    </div>
                                </div>

                                <div class="blog-detail-share">
                                    <b-dropdown variant="link" toggle-class="p-0" no-caret right>
                                        <template #button-content>
                                            <feather-icon size="21" icon="Share2Icon" class="text-body" />
                                        </template>
                                        <b-dropdown-item v-for="icon in socialShareIcons" :key="icon" href="#">
                                            <feather-icon :icon="icon" size="18" />
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </b-card>
                    </b-col>

                    <!-- <b-col id="blogComment" cols="12" class="mt-2">
                        <h6 class="section-label">Comment</h6>
                        <b-card>
                            <b-media no-body>
                                <b-media-aside class="mr-75">
                                    <b-avatar :src="thumbnailImage" size="38" />
                                </b-media-aside>
                                <b-media-body>
                                    <h6 class="font-weight-bolder mb-25">Farshid Sohrabiani</h6>
                                    <b-card-text>July 26, 2021</b-card-text>
                                    <b-card-text>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, enim! Ut
                                        deleniti porro sint.
                                    </b-card-text>
                                    <b-link>
                                        <div class="d-inline-flex align-items-center">
                                            <feather-icon icon="CornerUpLeftIcon" size="18" class="mr-50" />
                                            <span>Reply</span>
                                        </div>
                                    </b-link>
                                </b-media-body>
                            </b-media>
                        </b-card>
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <h6 class="section-label">Leave a Comment</h6>
                        <b-card>
                            <b-form>
                                <b-row>
                                    <b-col sm="6">
                                        <b-form-group class="mb-2">
                                            <b-form-input name="name" placeholder="Name" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group class="mb-2">
                                            <b-form-input name="email" type="email" placeholder="Email" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group class="mb-2">
                                            <b-form-input name="website" placeholder="Website" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group class="mb-2">
                                            <b-form-textarea name="textarea" rows="4" placeholder="Website" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="commentCheckmark"
                                            name="checkbox-1"
                                            class="mb-2"
                                        >
                                            Save my name, email, and website in this browser for the next time I
                                            comment.
                                        </b-form-checkbox>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                                            Post Comment
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col> -->
                </b-row>
            </div>

            <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
                <b-form-group class="blog-search">
                    <b-input-group class="input-group-merge">
                        <b-form-input v-model="searchQuery" placeholder="Search here" />
                        <b-input-group-append class="cursor-pointer" @click="searchPostsHandler" is-text>
                            <feather-icon icon="SearchIcon" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <div class="blog-recent-posts mt-3">
                    <h6 class="section-label mb-75">Recent Posts</h6>
                    <b-media
                        v-for="(recentPost, index) in recentPosts"
                        :key="index"
                        no-body
                        :class="index ? 'mt-2' : ''"
                    >
                        <b-media-aside class="mr-2">
                            <b-link :to="{ name: 'posts-show', params: { id: recentPost.id } }">
                                <b-img
                                    :src="recentPost.featured_image || thumbnailImage"
                                    width="100"
                                    rounded
                                    height="70"
                                />
                            </b-link>
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="blog-recent-post-title">
                                <b-link
                                    class="text-body-heading"
                                    :to="{ name: 'posts-show', params: { id: recentPost.id } }"
                                >
                                    {{ stringLimit(recentPost.title, 36) }}
                                </b-link>
                            </h6>
                            <span class="text-muted mb-0">
                                {{ formatDate(recentPost.created_at) }}
                            </span>
                        </b-media-body>
                    </b-media>
                </div>

                <div class="blog-categories mt-3">
                    <h6 class="section-label mb-1">Tags</h6>
                    <div v-if="tags.length > 0">
                        <div
                            v-for="(tag, index) in tags"
                            :key="index"
                            class="d-flex justify-content-start align-items-center mb-75"
                        >
                            <b-link @click="tagHandler(tag)">
                                <b-avatar rounded size="32" :variant="getRandomColor()" class="mr-75">
                                    <feather-icon icon="TagIcon" size="16" />
                                </b-avatar>
                                <span class="blog-category-title text-body">
                                    {{ tag.name.en }}
                                </span>
                            </b-link>
                        </div>
                    </div>
                    <div v-else class="text-muted">Tags not found</div>
                </div>
            </div>
        </content-with-sidebar>
        <b-modal size="xl" v-model="mitreAttackFlag" hide-footer title="Mitre Att&ck" v-if="techniqueInfo">
            <div class="bg-light-toggle p-1 rounded">
                <div>
                    <strong class="d-block">Name:</strong>
                    <span>{{ techniqueInfo.name }}</span>
                </div>
                <br />
                <div>
                    <strong class="d-block">Description:</strong>
                    <span>{{ techniqueInfo.description }}</span>
                </div>
                <br />
                <div>
                    <strong class="d-block">Tactics:</strong>
                    <span>{{ techniqueInfo.tactics }}</span>
                </div>
            </div>
            <div class="bg-light-toggle p-1 rounded mt-3" v-if="techniqueInfo.sub_technique">
                <div class="mb-1">
                    <h5 class="font-weight-bolder">Parent Technique</h5>
                </div>
                <div>
                    <small class="d-block">
                        <strong> Name: </strong>
                    </small>
                    <small>
                        {{ techniqueInfo.sub_technique.name }}
                    </small>
                </div>
                <hr />
                <div>
                    <small class="d-block">
                        <strong> Description: </strong>
                    </small>
                    <small>
                        {{ techniqueInfo.sub_technique.description }}
                    </small>
                </div>
                <hr />
                <div>
                    <small class="d-block">
                        <strong> Tactics: </strong>
                    </small>
                    <small>
                        {{ techniqueInfo.sub_technique.tactics }}
                    </small>
                </div>
            </div>
            <div class="bg-light-toggle p-1 rounded mt-3" v-if="techniqueRelations.length > 0">
                <div class="mb-1">
                    <h5 class="font-weight-bolder">Technique Relation</h5>
                </div>
                <div v-for="(techniqueRelation, index) in techniqueRelations" :key="index">
                    <b-card class="mb-1">
                        <span class="text-info font-weight-bolder d-block mb-1 text-capitalize">
                            {{ techniqueRelation.type }}
                        </span>
                        <b-card-text>
                            <div>
                                <small class="font-weight-bold d-block"> Name: </small>
                                <small>
                                    {{ techniqueRelation.name }}
                                </small>
                            </div>
                            <div>
                                <small class="font-weight-bold d-block"> Description: </small>
                                <small>
                                    {{ techniqueRelation.description }}
                                </small>
                            </div>
                        </b-card-text>
                    </b-card>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCardTitle,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import { DateTime } from "luxon";
import axios from "axios";
import _ from "lodash";

export default {
    components: {
        BFormInput,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BBadge,
        BCardText,
        BCardTitle,
        BDropdown,
        BForm,
        BDropdownItem,
        BFormTextarea,
        BFormCheckbox,
        BButton,
        ContentWithSidebar,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            searchQuery: "",
            commentCheckmark: "",
            blogDetail: [],
            blogSidebar: {},
            // related posts
            recentPosts: [],
            // post
            isFavorite: false,
            postId: undefined,
            properties: [],
            title: undefined,
            createdAt: undefined,
            description: undefined,
            mitreAttackFlag: false,
            featuredImage: undefined,
            attachments: [],
            socialShareIcons: ["GithubIcon", "GitlabIcon", "FacebookIcon", "TwitterIcon", "LinkedinIcon"],
            techniqueInfo: undefined,
            techniqueRelations: [],
            author: undefined,
            tags: [],
            spl: undefined,
            // tags colors
            colors: [
                "light-primary",
                "light-secondary",
                "light-success",
                "light-warning",
                "light-danger",
                "light-info",
            ],
        };
    },
    computed: {
        isPropertiesSet() {
            return _.size(this.properties) > 0;
        },
        thumbnailImage() {
            return require("@/assets/images/posts/thumbnail.png");
        },
    },
    beforeRouteUpdate(to, from, next) {
        this.getPost(to.params.id);
        next();
    },
    mounted() {
        this.getPost(this.$route.params.id);
        this.getRecentPosts(4, this.$route.params.id);
    },
    methods: {
        kFormatter,
        searchPostsHandler() {
            return this.$router.push({
                name: "posts-index",
                query: {
                    title: this.searchQuery,
                },
            });
        },
        getRandomColor() {
            return this.colors[_.random(0, this.colors.length - 1)];
        },
        getPost(id) {
            axios
                .get(`${process.env.VUE_APP_URL}/posts/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                })
                .then(({ data }) => {
                    this.postId = data.id;
                    this.title = data.title;
                    this.createdAt = data.created_at;
                    this.description = data.description;
                    this.spl = data.spl;
                    this.featuredImage = data.featured_image ? data.featured_image.url : this.thumbnailImage;
                    this.attachments = data.attachments;
                    this.properties = _.groupBy(data.values, "attribute.name");
                    this.author = `${data.author.first_name} ${data.author.last_name}`;
                    this.tags = data.tags;
                    this.isFavorite = data.is_favorite;
                });
        },
        formatDate(date) {
            return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
        },
        stringLimit(str, limit) {
            return _.truncate(str, {
                length: limit,
                omission: " ...",
            });
        },
        toStartCase(value, delimiter, separator) {
            return _.startCase(_.join(_.split(value, delimiter), separator));
        },
        techniqueHandler({ name }) {
            axios
                .get(`${process.env.VUE_APP_URL}/techniques/${name}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                })
                .then(({ data }) => {
                    if (_.size(data.relationships) > 0) {
                        this.techniqueRelations = _.flatten(
                            _.map(data.relationships, (item) => {
                                const rel = _.pickBy(_.pick(item, ["mitigation", "software", "group"]), _.identity);
                                return _.map(rel, (_item, index) => {
                                    return { ..._item, type: index };
                                });
                            })
                        );
                    }
                    this.techniqueInfo = _.pick(data, ["name", "description", "tactics", "sub_technique"]);
                    this.mitreAttackFlag = true;
                });
        },
        tagHandler(tag) {
            return this.$router.push({
                name: "posts-index",
                query: {
                    tags: tag.name.en,
                },
            });
        },
        favoriteToggleHandler(action) {
            axios
                .post(
                    `${process.env.VUE_APP_URL}/favorites/${this.postId}/${action}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    }
                )
                .then((response) => {
                    this.isFavorite = !this.isFavorite;
                });
        },
        getRecentPosts(limit, id) {
            axios
                .get(`${process.env.VUE_APP_URL}/posts/recent`, {
                    params: { limit, id },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                })
                .then(({ data }) => {
                    this.recentPosts = data;
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
.card > .card-img-top {
    height: 300px !important;
    object-fit: cover;
    object-position: center;
}
.mitre_badge:hover {
    cursor: pointer;
}
</style>
